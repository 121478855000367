import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import axios from "axios"
import moment from "moment"
import { UploadOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons"
import {
  Breadcrumb,
  Typography,
  Button,
  Form,
  Input,
  Divider,
  Modal,
  message,
  Upload,
} from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

import fileSizeChecker from "../../../utils/fileSizeChecker"
import pdfChecker from "../../../utils/pdfChecker"
import getTime from "../../../utils/getTime"

const TahapDuaPage = () => {
  const { t } = useI18next()

  const [loading, setLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [modalTitle, setModalTitle] = useState("")

  const [modalDescription, setModalDescription] = useState("")

  const [isDirectLink, setIsDirectLink] = useState(false)

  const [fileList, setFileList] = useState([])

  const [form] = Form.useForm()

  const [isRegistrationClose, setIsRegistrationClose] = useState(false)

  const formConfig = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12,
    },
    size: "large",
    layout: "vertical",
    scrollToFirstError: true,
    autoComplete: "off",
    className: "flat-form",
  }

  const onFinish = async ({ no_registrasi }) => {
    const formData = new FormData()

    formData.append("dokumen_proposal", fileList[0].originFileObj)
    formData.append("no_registrasi", no_registrasi)

    setLoading(true)

    axios({
      url: `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/storeTahap2`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        const responseCode = res.data.code
        const responseMessage = res.data.message

        if (responseCode === 200) {
          setModalTitle("Upload Proposal Bisnis Berhasil!")
          setModalDescription(
            "Unggahan proposal bisnis anda telah kami terima. Silakan cek email untuk petunjuk selanjutnya."
          )

          setIsDirectLink(true)
        }

        if (responseCode === 400) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription(
            "Ada input data yang perlu diisi, silahkan lengkapi terlebih dahulu."
          )

          if (responseMessage === "No access") {
            setModalDescription("Anda belum mengunggah dokumen pada tahap ini.")
          }

          setIsDirectLink(false)
        }

        if (responseCode === 404) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription("Nomor registrasi salah.")

          setIsDirectLink(false)
        }

        if (responseCode === 500) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription(
            "Silahkan hubungi call center kami di 134 atau +62-21-23507011 untuk bantuan kendala anda."
          )

          setIsDirectLink(false)
        }

        if (setIsDirectLink) {
          form.resetFields()
        }

        setLoading(false)
        setIsModalOpen(true)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  const handleOk = () => {
    setIsModalOpen(false)

    if (isDirectLink) {
      window.location.href = "https://lpdp.kemenkeu.go.id"
    }
  }

  const beforeUploadHandler = () => {
    return false
  }

  const onChangeHandler = ({ fileList: newFile }) => {
    if (newFile.length > 0) {
      const isPDF = pdfChecker(newFile[0])
      const isFileSizeOK = fileSizeChecker(newFile[0], 5)

      if (!isPDF) {
        message.error("You can only upload PDF file!")
      }

      if (!isFileSizeOK) {
        message.error("The file must smaller than 5MB!")
      }

      if (isPDF && isFileSizeOK) {
        setFileList(newFile)
        form.setFieldsValue({ dokumen_proposal: newFile[0].originFileObj })
      }
    } else {
      setFileList([])
      form.setFieldsValue({ dokumen_proposal: null })
    }
  }

  const itemRenderHandler = (originNode, file, fileList, actions) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a
          href={file.url || URL.createObjectURL(file.originFileObj)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {file.name}
        </a>
        <span>
          <Button
            type="link"
            href={file.url || URL.createObjectURL(file.originFileObj)}
            target="_blank"
            rel="noopener noreferrer"
            icon={<EyeOutlined />}
          />
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => actions.remove(file)}
          />
        </span>
      </div>
    )
  }

  useEffect(() => {
    async function displayTime() {
      try {
        const data = await getTime()

        const { current_time, end_time } = data.data

        const formattedCurrentDate = moment(current_time).unix() * 1000

        const formattedEndDate = moment(end_time).unix() * 1000

        setIsRegistrationClose(formattedCurrentDate >= formattedEndDate)
      } catch (error) {
        console.error("Error fetching time:", error)
      }
    }

    // displayTime()
  }, [])

  return (
    <Layout module_name="scholarship" page_name="registration-form">
      {isRegistrationClose && (
        <React.Fragment>
          <Seo title="Pendaftaran Gelombang II Ditutup" />

          <Container>
            <div
              className="section text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                flexDirection: "column",
              }}
            >
              <h4 style={{ color: "#434343" }}>Halo Entrepreneur Indonesia</h4>
              <h3>Pendaftaran Gelombang II Ditutup</h3>
              <h5 style={{ color: "#8c8c8c" }}>
                Terima kasih atas partisipasi Anda.
              </h5>
              <Button size="large" type="link">
                <Link to="/">Kembali ke Beranda</Link>
              </Button>
            </div>
          </Container>
        </React.Fragment>
      )}

      {!isRegistrationClose && (
        <React.Fragment>
          <Seo
            title="Form Pendaftaran Business Competition"
            module_name="scholarship"
            page_name="registration-form"
          />

          <Container>
            <Breadcrumb separator={<ChevronRight />}>
              <Breadcrumb.Item>
                <Link to="/">{t("Beranda")}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {t("Form Pendaftaran Business Competition")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Container>

          <Container>
            <Typography.Title>
              {t("Form Pendaftaran - Tahap 2")}
            </Typography.Title>

            <Divider />

            <Form
              {...formConfig}
              form={form}
              name="form-data-usaha"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Nomor Registrasi"
                name="no_registrasi"
                rules={[
                  {
                    required: true,
                    message: "Please input your Registration ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="dokumen_proposal"
                label="Upload Proposal Bisnis"
                rules={[
                  {
                    required: true,
                    message: "Please upload Proposal Bisnis!",
                  },
                ]}
              >
                <Typography.Text>
                  {t(
                    "Proposal bisnis hanya untuk format .pdf dengan maksimum ukuran sebesar 5 Mb."
                  )}
                </Typography.Text>

                <Upload
                  name="dokumen_proposal"
                  fileList={fileList}
                  beforeUpload={beforeUploadHandler}
                  onChange={onChangeHandler}
                  itemRender={itemRenderHandler}
                >
                  {fileList.length > 0 ? null : (
                    <Button icon={<UploadOutlined />} style={{ marginTop: 20 }}>
                      Select File
                    </Button>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 16 }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("Submit")}
                </Button>
              </Form.Item>
            </Form>

            <Modal
              visible={isModalOpen}
              footer={[
                <Button
                  key="ok"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Ok
                </Button>,
              ]}
              onCancel={() => setIsModalOpen(false)}
            >
              <Typography.Title
                className="text-center"
                style={{ marginTop: 15 }}
              >
                {modalTitle}
              </Typography.Title>

              <p className="text-center">{modalDescription}</p>
            </Modal>
          </Container>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default TahapDuaPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
